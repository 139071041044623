import { default as academyWMW5PTXKDhMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/academy.vue?macro=true";
import { default as collectionsLdorU2Gqg3Meta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/collections.vue?macro=true";
import { default as downloadsJcYCPpPBPzMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/downloads.vue?macro=true";
import { default as indexPc36yBXA6NMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/index.vue?macro=true";
import { default as subscriptionIy9DX6KglyMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/subscription.vue?macro=true";
import { default as barnBDMS7Z91jMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/bar.vue?macro=true";
import { default as _91sessionId_93YMKaSYjgSaMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/checkout/[sessionId].vue?macro=true";
import { default as foooU8UEkGR7nMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/foo.vue?macro=true";
import { default as forgot_45passwordIf4MhASxPXMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/forgot-password.vue?macro=true";
import { default as hdriskGslmyDepxMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/hdris.vue?macro=true";
import { default as indexzOF9HdvkSOMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/index.vue?macro=true";
import { default as licensing0tSgmrfYPcMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/licensing.vue?macro=true";
import { default as loginch2FgnH9XnMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/login.vue?macro=true";
import { default as logout6NR3Dxn1VZMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/logout.vue?macro=true";
import { default as _91planId_936uD9PlsQHtMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/[planId].vue?macro=true";
import { default as commercial5lonxrzXCKMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/commercial.vue?macro=true";
import { default as indexMBX3A1twhlMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/index.vue?macro=true";
import { default as personalgtFTdNURIsMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/personal.vue?macro=true";
import { default as redeemG73JuF6myoMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/redeem.vue?macro=true";
import { default as registerSJsITicyDcMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/register.vue?macro=true";
export default [
  {
    name: "account-academy",
    path: "/account/academy",
    meta: academyWMW5PTXKDhMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/academy.vue").then(m => m.default || m)
  },
  {
    name: "account-collections",
    path: "/account/collections",
    meta: collectionsLdorU2Gqg3Meta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/collections.vue").then(m => m.default || m)
  },
  {
    name: "account-downloads",
    path: "/account/downloads",
    meta: downloadsJcYCPpPBPzMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/downloads.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexPc36yBXA6NMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionIy9DX6KglyMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: "bar",
    path: "/bar",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/bar.vue").then(m => m.default || m)
  },
  {
    name: "checkout-sessionId",
    path: "/checkout/:sessionId()",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/checkout/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "foo",
    path: "/foo",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/foo.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "hdris",
    path: "/hdris",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/hdris.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "licensing",
    path: "/licensing",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/licensing.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout6NR3Dxn1VZMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "plans-planId",
    path: "/plans/:planId()",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/[planId].vue").then(m => m.default || m)
  },
  {
    name: "plans-commercial",
    path: "/plans/commercial",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/commercial.vue").then(m => m.default || m)
  },
  {
    name: "plans",
    path: "/plans",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "plans-personal",
    path: "/plans/personal",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/personal.vue").then(m => m.default || m)
  },
  {
    name: "redeem",
    path: "/redeem",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/redeem.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/register.vue").then(m => m.default || m)
  }
]